import { Tile } from './Tile'
import { IProduct } from '@typings/tile'
import Constants from '@typings/constants'
import { dateStringToTimestamp } from 'helpers/utils'
import { Offer } from './Offer'

/**
 * An abstract class representing a product that can be purchased.
 * One of {@link TVODProduct} or {@link Subscription} can be instantiated.
 * @property {Array<Offer>} [offers=[]] Offers associated with the product
 * @property {String|Number} transactionId The transaction ID is different thant the product ID, it is retrieved for some backend after an entitlement. It is used by some API (e.g Market One for cancellation)
 * @property {String} currency Currency of the prices listed for each offer
 * @property {Boolean} [isEntitled=false] Flag indicating whether the user has the right to consume/access the content associated with the product
 * @property {Boolean} [isAvailable=false] Flag indicating whether the product is available for purchase
 * @property {Boolean} isPermanentEntitlement Flag indicating if the product is owned and purchased forever (used for VOD EST)
 * @property {Number} entitlementStart Timestamp indicating the start of the entitlement period
 * @property {Number} entitlementEnd Timestamp indicating the end of the entitlement period
 * @property {Number} validityStart Timestamp indicating the overall start of validity of the products for ALL users
 * @property {Number} validityEnd Timestamp indicating the overall end of validity of the products for ALL users (product should be treated as out-of-service)
 * @property {String} source Internal information to know from which backend Product has been getted either from external backend or IFS Proxy (@Link SOURCE)
 * @property {String} category Product category (Not provided by all backend)
 * @property {Array<Constants.businessType>} purchaseRestrictionByBusiness Indicates for what type of user the content can be bought (B2B/B2B2C/B2C)
 */
export class Product extends Tile {
    children?: any[]
    promotions?: any[]
    canUpgradeTo?: any[]
    canDowngradeTo?: any[]
    offers: Offer[]
    transactionId: string | number
    currency: string
    entitlementStart: number
    entitlementEnd: number
    isPermanentEntitlement: boolean
    validityStart: number
    validityEnd: number
    isEntitled: boolean
    isAvailable: boolean
    purchaseRestrictionByBusiness: keyof typeof Constants.businessType
    category: string
    source: string
    trialGroup: string
    productGroups?: any[]
    catalogOffers?: any[]

    //EMI Parameter
    buttonText?: string
    backgroundColor?: string
    textColor?: string
    video?: string
    horizontalAlignment?: string
    verticalAlignment?: string
    interactiveTextColor?: string
    interactiveBackgroundColor?: string
    pricePrefix?: string
    bannerSize?: string
    blockSize?: string
    startingPrice?: any
    legalText?: any
    legalUrl?: any
    isLegalText?: any
    isLegalUrl?: any
    textSubscribedActive?: any
    textSubscribedInactive?: any
    textUnsubscribed?: any
    isActionButtonOn?: string
    isDescription?: string
    isStartingPrice?: string
    isThumbnail?: string
    isTitle?: string
    isEyeBrow?: any
    eyebrowText?: any
    useVideo?: string
    backdropPortrait?: string
    externalLink?: string
    pageId?: string

    constructor(props: IProduct) {
        super(props)

        this.children = props.children
        this.promotions = props.promotions
        this.canUpgradeTo = props.canUpgradeTo
        this.canDowngradeTo = props.canDowngradeTo
        this.transactionId = props.transactionId
        this.currency = props.currency
        this.entitlementStart = dateStringToTimestamp(props.entitlementStart)
        this.entitlementEnd = dateStringToTimestamp(props.entitlementEnd)
        this.isPermanentEntitlement = props.isPermanentEntitlement

        this.validityStart = props.validityStart
        this.validityEnd = props.validityEnd

        const computePropValue = this._makeComputePropValue(props)

        this.offers = computePropValue('offers', [])
        this.isEntitled = !!computePropValue('isEntitled', false)
        this.isAvailable = !!computePropValue('isAvailable', false)
        this.purchaseRestrictionByBusiness = computePropValue('purchaseRestrictionByBusiness', [])
        this.format = 'landscape'
        this.category = props.category
        this.source = computePropValue('source', Product.SOURCE.EXTERNAL)
        this.trialGroup = computePropValue('trialGroup', '')
        this.productGroups = props.productGroups
        this.catalogOffers = props.catalogOffers
    }

    static SOURCE = {
        EXTERNAL: 'EXTERNAL_SOURCE', // Product retrieved from external source
        IFS_PROXY: 'PROXY_SOURCE', // Product retrieved from IFS Proxy (Warning IFS proxy does not handle/provide entitlements you should resolve it through another backend call)
    }

    /**
     * @return {Boolean} always `true` since this class represent a Product
     */
    isProduct() {
        return true
    }

    /**
     * Indicates whether a Product is a {@link TVODProduct}
     * Always false
     * @returns {Boolean}
     */
    isTVOD() {
        return false
    }

    /**
     * Indicates whether a Product is a {@link Subscription}
     * Always false
     * @returns {Boolean}
     */
    isSubscription() {
        return false
    }

    /**
     * Returns `true` if product is entitled at the time method is invoked (ie: entitlementEnd is not yet reached).
     *
     * @returns {Boolean}
     */
    isEntitledNow() {
        return this.isEntitled && Date.now() < this.entitlementEnd
    }

    /**
     * By default a product SHALL not be directly displayed in the form of a Tile
     * A TVOD Product is not directly displayed but it SHALL be the VOD Content which is attached to a TVOD Product
     * A Subscription is displayed by default
     *
     * By displayed it means displayable in a Tile, not during the product/offer selection before a purchase within a list
     * @returns {Boolean}
     */
    isDisplayable() {
        return false
    }
}
